@import "slick-carousel/slick/slick-theme.css";
@import "slick-carousel/slick/slick.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* This stylesheet generated by Transfonter (https://transfonter.org) on July 4, 2017 2:58 PM */

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-BoldCondIt.eot');
    src: local('Myriad Pro Bold Condensed Italic'), local('../../font/MyriadPro-BoldCondIt'),
    url('../../font/MyriadPro-BoldCondIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-BoldCondIt.woff') format('woff'),
    url('../../font/MyriadPro-BoldCondIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-BlackIt.eot');
    src: local('Myriad Pro Black Italic'), local('../../font/MyriadPro-BlackIt'),
    url('../../font/MyriadPro-BlackIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-BlackIt.woff') format('woff'),
    url('../../font/MyriadPro-BlackIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-LightIt.eot');
    src: local('Myriad Pro Light Italic'), local('../../font/MyriadPro-LightIt'),
    url('../../font/MyriadPro-LightIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-LightIt.woff') format('woff'),
    url('../../font/MyriadPro-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-BlackCond.eot');
    src: local('Myriad Pro Black Condensed'), local('../../font/MyriadPro-BlackCond'),
    url('../../font/MyriadPro-BlackCond.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-BlackCond.woff') format('woff'),
    url('../../font/MyriadPro-BlackCond.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-Cond.eot');
    src: local('Myriad Pro Condensed'), local('../../font/MyriadPro-Cond'),
    url('../../font/MyriadPro-Cond.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-Cond.woff') format('woff'),
    url('../../font/MyriadPro-Cond.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-Bold.eot');
    src: local('Myriad Pro Bold'), local('../../font/MyriadPro-Bold'),
    url('../../font/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-Bold.woff') format('woff'),
    url('../../font/MyriadPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-BoldIt.eot');
    src: local('Myriad Pro Bold Italic'), local('../../font/MyriadPro-BoldIt'),
    url('../../font/MyriadPro-BoldIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-BoldIt.woff') format('woff'),
    url('../../font/MyriadPro-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-BoldSemiCnIt.eot');
    src: local('Myriad Pro Bold SemiCondensed Italic'), local('../../font/MyriadPro-BoldSemiCnIt'),
    url('../../font/MyriadPro-BoldSemiCnIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-BoldSemiCnIt.woff') format('woff'),
    url('../../font/MyriadPro-BoldSemiCnIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-CondIt.eot');
    src: local('Myriad Pro Condensed Italic'), local('../../font/MyriadPro-CondIt'),
    url('../../font/MyriadPro-CondIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-CondIt.woff') format('woff'),
    url('../../font/MyriadPro-CondIt.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-LightCond.eot');
    src: local('Myriad Pro Light Condensed'), local('../../font/MyriadPro-LightCond'),
    url('../../font/MyriadPro-LightCond.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-LightCond.woff') format('woff'),
    url('../../font/MyriadPro-LightCond.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-SemiCn.eot');
    src: local('Myriad Pro SemiCondensed'), local('../../font/MyriadPro-SemiCn'),
    url('../../font/MyriadPro-SemiCn.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-SemiCn.woff') format('woff'),
    url('../../font/MyriadPro-SemiCn.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-SemiCnIt.eot');
    src: local('Myriad Pro SemiCondensed Italic'), local('../../font/MyriadPro-SemiCnIt'),
    url('../../font/MyriadPro-SemiCnIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-SemiCnIt.woff') format('woff'),
    url('../../font/MyriadPro-SemiCnIt.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-BoldCond.eot');
    src: local('Myriad Pro Bold Condensed'), local('../../font/MyriadPro-BoldCond'),
    url('../../font/MyriadPro-BoldCond.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-BoldCond.woff') format('woff'),
    url('../../font/MyriadPro-BoldCond.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-LightSemiExtIt.eot');
    src: local('Myriad Pro Light SemiExtended Italic'), local('../../font/MyriadPro-LightSemiExtIt'),
    url('../../font/MyriadPro-LightSemiExtIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-LightSemiExtIt.woff') format('woff'),
    url('../../font/MyriadPro-LightSemiExtIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-Semibold.eot');
    src: local('Myriad Pro Semibold'), local('../../font/MyriadPro-Semibold'),
    url('../../font/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-Semibold.woff') format('woff'),
    url('../../font/MyriadPro-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-Black.eot');
    src: local('Myriad Pro Black'), local('../../font/MyriadPro-Black'),
    url('../../font/MyriadPro-Black.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-Black.woff') format('woff'),
    url('../../font/MyriadPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-Regular.eot');
    src: local('Myriad Pro Regular'), local('../../font/MyriadPro-Regular'),
    url('../../font/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-Regular.woff') format('woff'),
    url('../../font/MyriadPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-LightSemiCn.eot');
    src: local('Myriad Pro Light SemiCondensed'), local('../../font/MyriadPro-LightSemiCn'),
    url('../../font/MyriadPro-LightSemiCn.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-LightSemiCn.woff') format('woff'),
    url('../../font/MyriadPro-LightSemiCn.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-BlackCondIt.eot');
    src: local('Myriad Pro Black Condensed Italic'), local('../../font/MyriadPro-BlackCondIt'),
    url('../../font/MyriadPro-BlackCondIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-BlackCondIt.woff') format('woff'),
    url('../../font/MyriadPro-BlackCondIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-LightCondIt.eot');
    src: local('Myriad Pro Light Condensed Italic'), local('../../font/MyriadPro-LightCondIt'),
    url('../../font/MyriadPro-LightCondIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-LightCondIt.woff') format('woff'),
    url('../../font/MyriadPro-LightCondIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-LightSemiExt.eot');
    src: local('Myriad Pro Light SemiExtended'), local('../../font/MyriadPro-LightSemiExt'),
    url('../../font/MyriadPro-LightSemiExt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-LightSemiExt.woff') format('woff'),
    url('../../font/MyriadPro-LightSemiExt.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-BlackSemiCnIt.eot');
    src: local('Myriad Pro Black SemiCondensed Italic'), local('../../font/MyriadPro-BlackSemiCnIt'),
    url('../../font/MyriadPro-BlackSemiCnIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-BlackSemiCnIt.woff') format('woff'),
    url('../../font/MyriadPro-BlackSemiCnIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-SemiboldCondIt.eot');
    src: local('Myriad Pro Semibold Condensed Italic'), local('../../font/MyriadPro-SemiboldCondIt'),
    url('../../font/MyriadPro-SemiboldCondIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-SemiboldCondIt.woff') format('woff'),
    url('../../font/MyriadPro-SemiboldCondIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-SemiboldCond.eot');
    src: local('Myriad Pro Semibold Condensed'), local('../../font/MyriadPro-SemiboldCond'),
    url('../../font/MyriadPro-SemiboldCond.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-SemiboldCond.woff') format('woff'),
    url('../../font/MyriadPro-SemiboldCond.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-BoldSemiExtIt.eot');
    src: local('Myriad Pro Bold SemiExtended Italic'), local('../../font/MyriadPro-BoldSemiExtIt'),
    url('../../font/MyriadPro-BoldSemiExtIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-BoldSemiExtIt.woff') format('woff'),
    url('../../font/MyriadPro-BoldSemiExtIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-SemiboldSemiCn.eot');
    src: local('Myriad Pro Semibold SemiCondensed'), local('../../font/MyriadPro-SemiboldSemiCn'),
    url('../../font/MyriadPro-SemiboldSemiCn.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-SemiboldSemiCn.woff') format('woff'),
    url('../../font/MyriadPro-SemiboldSemiCn.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-BlackSemiExtIt.eot');
    src: local('Myriad Pro Black SemiExtended Italic'), local('../../font/MyriadPro-BlackSemiExtIt'),
    url('../../font/MyriadPro-BlackSemiExtIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-BlackSemiExtIt.woff') format('woff'),
    url('../../font/MyriadPro-BlackSemiExtIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-SemiboldSemiExtIt.eot');
    src: local('Myriad Pro Semibold SemiExtended Italic'), local('../../font/MyriadPro-SemiboldSemiExtIt'),
    url('../../font/MyriadPro-SemiboldSemiExtIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-SemiboldSemiExtIt.woff') format('woff'),
    url('../../font/MyriadPro-SemiboldSemiExtIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-LightSemiCnIt.eot');
    src: local('Myriad Pro Light SemiCondensed Italic'), local('../../font/MyriadPro-LightSemiCnIt'),
    url('../../font/MyriadPro-LightSemiCnIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-LightSemiCnIt.woff') format('woff'),
    url('../../font/MyriadPro-LightSemiCnIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-BoldSemiCn.eot');
    src: local('Myriad Pro Bold SemiCondensed'), local('../../font/MyriadPro-BoldSemiCn'),
    url('../../font/MyriadPro-BoldSemiCn.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-BoldSemiCn.woff') format('woff'),
    url('../../font/MyriadPro-BoldSemiCn.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-SemiboldSemiCnIt.eot');
    src: local('Myriad Pro Semibold SemiCondensed Italic'), local('../../font/MyriadPro-SemiboldSemiCnIt'),
    url('../../font/MyriadPro-SemiboldSemiCnIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-SemiboldSemiCnIt.woff') format('woff'),
    url('../../font/MyriadPro-SemiboldSemiCnIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-SemiboldIt.eot');
    src: local('Myriad Pro Semibold Italic'), local('../../font/MyriadPro-SemiboldIt'),
    url('../../font/MyriadPro-SemiboldIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-SemiboldIt.woff') format('woff'),
    url('../../font/MyriadPro-SemiboldIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-It.eot');
    src: local('Myriad Pro Italic'), local('../../font/MyriadPro-It'),
    url('../../font/MyriadPro-It.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-It.woff') format('woff'),
    url('../../font/MyriadPro-It.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-BoldSemiExt.eot');
    src: local('Myriad Pro Bold SemiExtended'), local('../../font/MyriadPro-BoldSemiExt'),
    url('../../font/MyriadPro-BoldSemiExt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-BoldSemiExt.woff') format('woff'),
    url('../../font/MyriadPro-BoldSemiExt.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-Light.eot');
    src: local('Myriad Pro Light'), local('../../font/MyriadPro-Light'),
    url('../../font/MyriadPro-Light.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-Light.woff') format('woff'),
    url('../../font/MyriadPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-SemiboldSemiExt.eot');
    src: local('Myriad Pro Semibold SemiExtended'), local('../../font/MyriadPro-SemiboldSemiExt'),
    url('../../font/MyriadPro-SemiboldSemiExt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-SemiboldSemiExt.woff') format('woff'),
    url('../../font/MyriadPro-SemiboldSemiExt.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-SemiExtIt.eot');
    src: local('Myriad Pro SemiExtended Italic'), local('../../font/MyriadPro-SemiExtIt'),
    url('../../font/MyriadPro-SemiExtIt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-SemiExtIt.woff') format('woff'),
    url('../../font/MyriadPro-SemiExtIt.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-BlackSemiCn.eot');
    src: local('Myriad Pro Black SemiCondensed'), local('../../font/MyriadPro-BlackSemiCn'),
    url('../../font/MyriadPro-BlackSemiCn.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-BlackSemiCn.woff') format('woff'),
    url('../../font/MyriadPro-BlackSemiCn.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-BlackSemiExt.eot');
    src: local('Myriad Pro Black SemiExtended'), local('../../font/MyriadPro-BlackSemiExt'),
    url('../../font/MyriadPro-BlackSemiExt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-BlackSemiExt.woff') format('woff'),
    url('../../font/MyriadPro-BlackSemiExt.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../font/MyriadPro-SemiExt.eot');
    src: local('Myriad Pro SemiExtended'), local('../../font/MyriadPro-SemiExt'),
    url('../../font/MyriadPro-SemiExt.eot?#iefix') format('embedded-opentype'),
    url('../../font/MyriadPro-SemiExt.woff') format('woff'),
    url('../../font/MyriadPro-SemiExt.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




*{
    line-height: 1.5;
    font-stretch: ultra-condensed;
}



.toggleDarkBtn {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 41px;
}
.toggleDarkBtn input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slideBtnTg {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d7d7d7;
    -webkit-transition: 0.5s;
    transition: 0.5s;

}
.slideBtnTg:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    left: 5px;
    bottom: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #282828;
    box-shadow:0 0 1px #d7d7d7;
}


input:checked + .slideBtnTg {
    background-color: #363738;
}
input:focus + .slideBtnTg {
    box-shadow: 0 0 1px #2196f3;
}
input:checked + .slideBtnTg:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-color: #d7d7d7;
}
.slideBtnTg.round {
    border-radius: 34px;
}
.slideBtnTg.round:before {
    border-radius: 50%;
}

html{
    text-rendering: optimizeLegibility;
    image-rendering: optimizeQuality;
}

.slick-arrow::before{
    display: none;
}

.slick-track{
    display: flex;
    flex-direction: row;
    align-items: center;
}



